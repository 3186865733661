body{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.todo{
    width: 400px;
    height: 80vh;
    background: var(--themeBlack);
    color: white;
    border-radius: 1rem;
    box-shadow: var(--shadow);
    padding: 1rem;
}
.todo>h1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: var(--themeRed);
    padding: 0.5rem;
    border-radius: 10px;

}
.todoForm{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}
.todoForm>:nth-child(1){
    border: none;
    width: 50%;
    padding: 0.5rem;
    outline: none;
    border-radius: 5px;
}
.todoForm>:nth-child(2){
   
    outline: none;
    border: none;
    cursor: pointer;
    background-color: var(--themeRed);
    color: white;
}
.todoForm>:nth-child(2):hover{
    background-color: white;
    color: var(--themeRed);
}
.menu{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}
.right{
    padding: 0rem 3rem;
}
.right>span:hover{
    color: var(--themeRed);
}
.btn{
    border: none;
    outline: none;
    background-color: var(--themeRed);
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
    margin-left: 7.5rem;


}